import React from 'react';
import { graphql } from 'gatsby';

import withLayout from '../layout';
import SEO from "../components/seo";

import "./postTemplate.scss";


const PostTemplate = (props) => {
  const { markdownRemark } = props.data;
  const { frontmatter, html } = markdownRemark;

  return (
    <div>
      <SEO
        title={frontmatter.title}
        keywords={[...frontmatter.tags]}
      />
      <section>
        <div>
          <h1>{frontmatter.title}</h1>
          <span>{frontmatter.date}</span>
        </div>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </section>
    </div>
  );
};


const customProps = {
    localeKey: 'blog',
};

export default withLayout(customProps)(PostTemplate);

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        tags
      }
    }
  }
`;
